import React from 'react';
import classNames from 'classnames';
import { ChangeEvent, FocusEvent, FunctionComponent } from 'react';

import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { useSubscribeToFormDisabledChanges, useSubscribeToFormValueChanges } from 'app/hooks/forms/use-update-on-form-changes.hooks';

type InnerFocusEvent = FocusEvent<HTMLInputElement>;
type InnerChangeEvent = ChangeEvent<HTMLInputElement>;

const innerOnBlur = (field: NavCarrierFormControl, outerOnBlur?: (e?: InnerFocusEvent) => any) => (e: InnerFocusEvent) => {
  if (!field.touched) {
    field.touch();
  }
  if (outerOnBlur) {
    outerOnBlur(e);
  }
};

const innerOnChange = (field: NavCarrierFormControl, outerOnChange?: (e: InnerChangeEvent) => any) => (e: InnerChangeEvent) => {
  if (field.value !== e.target.value) {
    field.setValue(e.target.value);
  }
  if (outerOnChange) {
    outerOnChange(e);
  }
};

export interface Props {
  field: NavCarrierFormControl;
  className?: string;
  readOnly?: boolean;
  onChange?: (e: ChangeEvent) => any;
  onBlur?: (e: FocusEvent) => any;

  [key: string]: any;
}

export const Input: FunctionComponent<Props> = ({field, className, ...props}) => {
  useSubscribeToFormValueChanges(field);
  useSubscribeToFormDisabledChanges(field);
  return (
    <input
      {...props}
      className={classNames('form-control', className)}
      value={field.value ?? ''}
      readOnly={props.readOnly ?? field.disabled}
      onChange={innerOnChange(field, props.onChange)}
      onBlur={innerOnBlur(field, props.onBlur)}
    />
  );
};
